import { StaticImage } from "gatsby-plugin-image";
import CareerDetail from "../../features/career_detail";
import { Box } from "@mui/material";

export default (props: Object) => {
  return (
    <CareerDetail
      {...props}
      locales={{
        it: {
          title: "Social media & content manager",
          seoDescription:
            "Essere lofter significa curiosità, ambizione e passione. Lavoriamo per portare innovazione nel mondo del software e dei prodotti digitali. Ti va di unirti a noi?",
          description:
            "Stiamo cercando un Social Media & Content Manager altamente motivato e creativo, che si occupi della gestione della nostra presenza online. Sarai responsabile della creazione di contenuti innovativi e coinvolgenti che riflettano la nostra esperienza nel settore tech.",
          roles: [
            {
              title: "Gestione dei Social Media",
              bullets: [
                // "Gestire i profili aziendali di Mabiloft su LinkedIn, Instagram, YouTube e altri canali social, garantendo una presenza coerente e professionale.",
                // "Creare e implementare un calendario editoriale per i post settimanali e mensili, coordinando contenuti visivi, video e testuali.",
                "Pubblicare e monitorare post regolari su LinkedIn (pagina aziendale e profili del team), includendo articoli e contenuti sul processo di sviluppo e le competenze digitali che utilizziamo.",
                "Coordinare la pubblicazione di reel e stories su Instagram che documentano la vita quotidiana di Mabiloft e i progetti in corso.",
                // "Monitorare e analizzare le metriche di engagement e la crescita delle community su ciascuna piattaforma, fornendo report periodici sulle performance.",
              ],
            },
            {
              title: "Creazione di Contenuti e Copy",
              bullets: [
                "Creare e revisionare contenuti testuali per i social e per il blog, gestendo la pianificazione e la pubblicazione degli articoli.",
                "Collaborare con il team di design per sviluppare visual assets (infografiche, video animati, immagini).",
                "Scrivere newsletter e contenuti per campagne di email marketing orientate alla lead generation.",
              ],
            },
            // {
            //   title: "Gestione YouTube e Podcast (in futuro)",
            //   bullets: [
            //     "Coordinare la creazione di contenuti per il canale YouTube, compresi tutorial tecnici, interviste al team e case study in formato video.",
            //     "Pianificare e gestire la produzione del podcast aziendale, inclusa la registrazione, editing e pubblicazione degli episodi.",
            //   ],
            // },
            {
              title: "Public Relations e Networking",
              bullets: [
                "Collaborare con il team per gestire e migliorare la presenza pubblica dell'azienda, favorendo la partecipazione a eventi rilevanti.",
                "Sviluppare collaborazioni con altre aziende del settore per migliorare la visibilità del brand.",
              ],
            },
            {
              title: "Monitoraggio e Reporting",
              bullets: [
                "Misurare le prestazioni di tutte le campagne e canali di comunicazione utilizzando strumenti di analytics (Google Analytics, LinkedIn Insights, Instagram Insights, ecc.).",
                "Fornire report mensili sulle attività social e proporre strategie di miglioramento continuo.",
              ],
            },
          ],
          prerequisites: [
            "Esperienza di 0-2 anni nella gestione di social media aziendali e privati.",
            "Buona conoscenza delle piattaforme LinkedIn, Instagram, YouTube e dei rispettivi strumenti di analytics.",
            "Eccellenti doti di comunicazione e scrittura, con particolare attenzione per il copy orientato ai social media e al blogging.",
            "Competenze solide nella creazione di contenuti multimediali, inclusi video e grafiche.",
            "Esperienza nella gestione di campagne di email marketing e SEO.",
            "Capacità di utilizzare strumenti di analisi come Google analytics.",
            "Conoscenza di software di design come Canva, Figma o Adobe.",
            "Capacità di lavorare in modo indipendente e proattivo, ma con forte spirito di collaborazione con i vari team.",
            // "Conoscenza delle best practices di PR e capacità di creare relazioni con media.",
          ],
          // softSkills: [
          //   {
          //     title: "Creatività e proattività:",
          //     description: "Capacità di proporre nuove idee e strategie.",
          //   },
          //   {
          //     title: "Orientamento ai risultati:",
          //     description:
          //       "Monitorare i KPI e ottimizzare le performance delle eventuali campagne.",
          //   },
          //   {
          //     title: "Organizzazione e time management:",
          //     description:
          //       "Gestire più progetti simultaneamente e rispettare le scadenze.",
          //   },
          // ],
          tasks: [
            "Creerai e programmerai post su LinkedIn e Instagram per più profili, collaborando con il team nella creazione di contenuti.",
            // "Pubblicherai stories e reel su Instagram, documentando la vita aziendale, i progetti e molto altro.",
            "Collaborerai per la produzione di contenuti per il blog e per la newsletter.",
            "Risponderai a commenti e interagirai con la community su tutte le piattaforme.",
            "Preparerai un report mensile sui risultati dei social media e proporrai miglioramenti basati sui dati.",
            "Interagirai per la partecipazione ad eventi e per pianificare interventi o interviste.",
          ],
          yearsOfExperience: 0,
          requiredTools: [
            {
              name: "LinkedIn",
              image: (
                <StaticImage
                  alt=""
                  src={
                    "../../features/career_detail/images/socialMediaLinkedin.png"
                  }
                  style={{
                    width: "70px",
                    marginTop: "4px",
                    marginBottom: "4px",
                  }}
                />
              ),
            },
            {
              name: "Instagram",
              image: (
                <StaticImage
                  alt=""
                  src={
                    "../../features/career_detail/images/socialMediaInstagram.webp"
                  }
                  style={{ width: "70px", margin: "4px" }}
                />
              ),
            },
            {
              name: "Google Analytics",
              image: (
                <StaticImage
                  alt=""
                  src={
                    "../../features/career_detail/images/socialMediaGoogleAnalytics.webp"
                  }
                  style={{ width: "70px", margin: "4px" }}
                />
              ),
            },
          ],
        },
      }}
    />
  );
};
